<template lang="pug">
  div
    .row
      .col-xxl-8
        .card.card-custom.gutter-b.example.example-compact(v-if='isReady')
          .card-header.pb-0
            .card-title
              h3.card-label
                | {{ $t(&apos;gis_image.batch_description&apos;) }}
            .card-toolbar
              .example-tools.justify-content-center
          .card-body.pt-3.pb-0
            b-form-group#fieldset-1(description='' :label="`${$t('gis_image.batch_name')} (TH)`" label-for='input-1')
              b-form-input#input-1(v-model='data.name_th' trim='' placeholder='untitle' :state="!(data.name_th == '')")
            b-form-group#fieldset-1(description='' :label="`${$t('gis_image.batch_name')} (EN)`" label-for='input-1')
              b-form-input#input-1(v-model='data.name_en' trim='' placeholder='untitle' :state="!(data.name_en == '')")
            b-row
              b-col
                b-form-group#fieldset-1(description='' :label="$t('gis_image.north')" label-for='input-1')
                  b-form-input#input-1(v-model='data.north' trim='' placeholder='North' :state="!(data.north == '')")
              b-col
                b-form-group#fieldset-1(description='' :label="$t('gis_image.east')" label-for='input-1')
                  b-form-input#input-1(v-model='data.east' trim='' placeholder='East' :state="!(data.east == '')")
            b-row
              b-col
                b-form-group#fieldset-1(description='' :label="$t('gis_image.south')" label-for='input-1')
                  b-form-input#input-1(v-model='data.south' trim='' placeholder='South' :state="!(data.south == '')")
              b-col
                b-form-group#fieldset-1(description='' :label="$t('gis_image.west')" label-for='input-1')
                  b-form-input#input-1(v-model='data.west' trim='' placeholder='West' :state="!(data.west == '')")
            b-form-group#fieldset-1(description='' :label="`${$t('gis_image.batch_desc')} (TH)`" label-for='input-1')
              b-form-textarea#textarea(v-model='data.description_th' rows='5' max-rows='8')
            b-form-group#fieldset-1(description='' :label="`${$t('gis_image.batch_desc')} (EN)`" label-for='input-1')
              b-form-textarea#textarea(v-model='data.description_en' rows='5' max-rows='8')
        b-overlay(:show='isSaving' no-wrap='')  
      .col-xxl-4
        .card.card-custom.gutter-b(v-if='isReady')
          .card-header.pb-0
            .card-title
              h3.card-label
                | {{ $t(&apos;gis_image.publish&apos;) }}
            .card-toolbar
              .example-tools.justify-content-center
          .card-body.pt-3.pb-0
            b-button.mb-5(variant='primary' block='' @click='savePost') Save
          b-overlay(:show='isSaving' no-wrap='')  
        .card.card-custom.gutter-b(v-if='isReady')
          .card-header.pb-0
            .card-title
              h3.card-label {{ $t(&apos;biodiversity.image_attachment&apos;) }}
            .card-toolbar
              .example-tools.justify-content-center
          .card-body.pt-3.pb-0
            b-form-group#fieldset-1.pt-3(description='' label='' label-for='input-1')
              #preview
                b-img.mb-3(v-if='hasImage' :src='imageSrc' fluid='' block='' rounded='')  
                b-img.mb-3(v-if='data.image_path && !hasImage' :src='data.image_path' fluid='' block='' rounded='')
              b-button.btn-sm.mb-5(v-if='hasImage' variant='danger' @click='clearImage' block='') Delete
              b-form-file(accept='image/jpeg, image/png' v-model='pictureFile' placeholder='กรุณาเลือกไฟล์' drop-placeholder='Drop file here...')
              b-button.mt-2(:href='data.image_path' size='sm' variant='info' fluid='' block='' rounded='' target='_blank') Export
          b-overlay(:show='isSaving' no-wrap='')  
        
        .card.card-custom.gutter-b(v-if='isReady')
          .card-header.pb-0
            .card-title
              h3.card-label {{ $t('GENERAL.attachment') }}
            .card-toolbar
              .example-tools.justify-content-center
          .card-body.pt-3.pb-0
            b-form-group#fieldset-1(description='' label='' label-for='input-1')
              b-form-file(
                v-model='attachFiles'
                placeholder='กรุณาเลือกไฟล์'
                drop-placeholder='Drop file here...'
                :file-name-formatter='formatNames'
                multiple=''
                :show='!hasFiles'
              )
              b-button.btn-sm.mt-5(v-if='hasFiles' variant='danger' @click='clearAttachFiles' block='') Clear
              .clearfix.text-center.pt-3(v-if='hasFiles')
                span.center  OR 
                b-button.btn-sm.mt-3(variant='primary' @click='uploadAttachFiles' block='') Upload {{ attachFiles.length }} file(s)
            b-table(small='' responsive='sm' :items='data.attachment' :fields="['filename']" style='overflow: hidden')
              template(#cell()='data')
                | {{ data.value | str_limit(30) }}
                b-button.btn-sm.pt-0.pb-0.pr-2.pl-2.float-right(variant='danger' @click='deleteAttachment(data.item.id)') x
          b-overlay(:show='isSaving || isUploading' no-wrap='')  


</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  name: 'gis_image_batch_add',
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      data: {
        id: 0,
        type: 'gis_image',
        name_th: '',
        description_th: '',
        name_en: '',
        description_en: '',
        south: '',
        west: '',
        north: '',
        east: '',
        date: '',
        image_path: '',
        attachment: [],
      },
      pictureFile: null,
      imageSrc: null,
      fields: [
        {
          key: 'id',
          label: '#',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Layer',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      // Attachment
      attachFiles: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'GIS Image Data', route: '/admin/gis_image'}, {title: 'Batch Data'}]);
    this.getData();
  },
  methods: {
    showAddModal() {
      if (this.data.id == 0) {
        this.$bvToast.toast('You have to save this data first.', {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
        return;
      }
      this.$refs.uploadModal.showModal({gis_id: this.data.id});
    },
    getData() {
      if (this.$route.name == 'gis_image.edit_batch') {
        this.mode = 'edit';
        this.$store
          .dispatch(Services.GET_BATCH_DATA, {id: this.$route.params.id})
          // go to which page after successfully login
          .then(res => {
            this.data = res.data[0];
            this.isReady = true;
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (
          this.data.name_th == '' ||
          this.data.name_en == '' ||
          this.data.north == '' ||
          this.data.east == '' ||
          this.data.south == '' ||
          this.data.west == ''
        ) {
          this.$bvToast.toast('Please complete the form', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_BATCH_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(Services.UPLOAD_IMAGE, formData)
                .then(res => {
                  if (res.status) {
                    this.data.image_path = res.data.image_path;

                    //Upload Attachment
                    this.uploadAttachFiles();

                    //Update image path
                    this.$store
                      .dispatch(Services.SAVE_BATCH_DATA, this.data)
                      .then(res => {
                        this.isSaving = false;
                        this.pictureFile = null;
                      })
                      .catch(error => {
                        this.isSaving = false;
                        // console.log(error);
                      });
                  }
                })
                .catch(error => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            this.data = res.data[0];
          }
          // console.log('Save data');
          this.isSaving = false;
          this.$bvToast.toast('Post Saved.', {
            title: 'Successfully',
            variant: 'success',
            solid: true,
          });
          if (this.$route.name == 'gis_image.add_batch') {
            this.$router.push({
              name: 'gis_image.edit_batch',
              params: {id: this.data.id},
            });
          }
        })
        .catch(error => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    clearImage() {
      this.pictureFile = null;
    },

    // Attachment
    uploadAttachFiles() {
      if (this.data.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            // console.log(item.name);
            formData.append('id', this.data.id);
            formData.append('table', 'gis_image');
            this.$store
              .dispatch('UPLOAD_ATTACH', formData)
              .then(res => {
                i++;
                if (res.status) {
                  this.$bvToast.toast('Upload [' + res.data.filename + '] successfully!', {
                    title: 'Successfully',
                    variant: 'success',
                    solid: true,
                  });
                } else {
                  this.$bvToast.toast(res.message, {
                    title: 'Upload Failed!',
                    variant: 'danger',
                    noAutoHide: true,
                    appendToast: true,
                    solid: true,
                  });
                }
                if (i === attachNum) {
                  this.getData();
                }
              })
              .catch(err => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 500);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.deleteConfirm(() => {
        this.isUploading = true;
        this.$store
          .dispatch('DELETE_ATTACH', {id: id})
          .then(res => {
            this.getData();
            this.isUploading = false;
          })
          .catch(err => {
            this.isUploading = false;
          });
      });
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
  watch: {
    pictureFile: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    'data.name': function(oldValue, newValue) {
      if (this.mode == 'add') {
        this.data.slug = this.slugify(this.data.name);
      }
    },
  },
};
</script>

<style></style>
